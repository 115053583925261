@import url(https://cdn.jsdelivr.net/gh/theeluwin/NotoSansKR-Hestia@master/stylesheets/NotoSansKR-Hestia.css);
$container_space:30px; //페이지별 좌우 기본 여백
$black:#121212;
body {
    color: $black;
    font-family:"Neue Montreal",'Noto Sans Korean', sans-serif;
    font-weight:500;
    padding: 0px;
    margin: 0px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    word-break: keep-all;
    font-size: 15px;
    background-color: #fff;
}



h1,
h2,
h3,
input,
select,
ul,
li,
button,p {
    margin: 0px;
    padding: 0px
}

button {
    color: inherit;
    border: none;
    background: none;
}

ul,
li {
    list-style: none;
}
a{
    color:inherit;
    text-decoration: none;;
}

.mobile_view{
    display:none;
}
.move_back{
    position: fixed;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    z-index: 9999;
    display:flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    .logo{
        position: relative;
z-index: 200;
opacity: 0;
&.active{
    opacity: 1;
}
    }
    svg{
     
        position: absolute;
        left: 0; top: 0;
        width: 100%; height: 100%;
        transform: rotate(180deg);
    }
}
.header{
    mix-blend-mode: difference;
    position: fixed;
    top:16px;
    display:flex;
    left:$container_space;
    right:$container_space;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    .logo{
        cursor: pointer;
        line-height: 0px;
    }
   
    .mobile_menu{
        display:none;
    }
    a{
        line-height: 0;
    }
    .main_menu{
        display:flex;
        gap:30px;
        align-items: center;
        color:#fbfbfb;
        li{
            text-transform: uppercase;
            font-size:22px;
            position: relative;
            cursor: pointer;
            &::before{
                position: absolute;
                content:'';
                width:6px;
                height:6px;
                border-radius: 3px;
                background-color: #fafafa;
                left:-12px;
                top:11px;
                transform: scale(0);
                transition-duration: 0.2s;
            }
         
            &:hover,&.active{
                &::before{
                    transform: scale(1);
                }
            }
        }
    }
}
.copy_space{
    display:inline-block;
    width:15vw;
}
.ani_int_t,.ani_t{
    display:inline-block;
}  
.line_t{
    overflow: hidden;   
    line-height: 6vw;
    margin-top:-1.9vw;
}
.contents_wrap{
    overflow: hidden;
    .main{
        .main_copy{
            font-size:5.1vw;
            line-height: 4.3vw;  
            pointer-events: none; 
          
        }
        &.m1{
            padding-top:calc(14vw + 200px);
            .main_copy{
                position: fixed;
                top:13.5vw;
                left:$container_space;
                height:200px;
                z-index: 10;
                color:#fafafa;
                mix-blend-mode: difference;
                animation-timing-function: linear;
                &.mc1{
                    margin-top:1vw;
                }
                &.mc2{
                    .line_down{
                        margin-top:8px;
                    }
                }
                .copy_space{
                    width:14vw;
                }
                .copy_space2{
                    width:7vw;
                }
                .copy_space3{
                    width:21.3vw;
                }
                
            }
            .sub_info{
                margin-top:125px;
                display:flex;
                justify-content: space-between;
                padding:0px $container_space;
                .si_right_section{
                    display:flex;
                    gap:100px;
                }
                .si_item{
                    font-size:24px;
                    line-height: 40px;
                    text-transform: uppercase;
                }
               

            }
            .main_img{
                position: relative;
                margin-top:16px;
                height:2200px;
                background-size: cover;
                padding:32px $container_space;
                overflow: hidden;


                .back_img{
                    width:100%;
                    height:2200px;
                    object-fit: cover;
                    position: absolute;
                    left:0px;
                    right:0px;
                    top:0px;
                    z-index: 1;
                    &.bi2{
                        opacity: 0;

                    }
            
                }
                .arrow_down{
                    position: fixed;
                    bottom:30px;
                    left:50%;
                    margin-left:-36px;
                    z-index: 3;
                }
                .sub_copy{
                    width:510px;
                    color:#fff;
                    font-size:18px;
                    line-height: 24px;
                    position: relative;
                    z-index: 2;
                    &.sc2{
                        position:absolute;
                        left:30%;
                        top:1400px;
                        opacity: 0;
                    }
                }

            }
         
        }
        &.m2{
            .business_section{
                display:flex;
                margin-top:300px;
                justify-content: center;
                position: relative;
                height:4500px;
                .business_desc{
                    position: absolute;
                    left:55%;
                    top:300px;
                    font-size:24px;
                    line-height: 29px;
                    z-index: 5;
                }
                .bs_title_items{
                    position: absolute;
                    top:-110px;
                    z-index: 5;
                
                    width:950px;
                    left:50%;
                    margin-left:-440px;
                    
                      
                    .bs_title_item{
                        position:absolute;
                        top:0px;
                        left:0px;
                 
                        font-size:80px;
                        line-height: 70px;
                        .bs_title_container{
                            overflow: hidden;
                            height:80px;
                            &:nth-child(2){
                                transform: translateY(-10px);
                            }
                            
                        }
                    }
                }
                .bs_img_items{
                    position: relative;
                    width:517px;
                    height:600px;

                    .bs_img_item{
                        left:0px;
                        right:0px;
                        top:0px;
                        height:600px;
                        position: absolute;
                        line-height: 0px;
                        transform-origin: top left;
                        overflow: hidden;
                        
                        img{
                            width:100%;
                            height:600px;
                            object-fit: cover;
                        }
                    }
                }
            }
            .team_section{
                width:70%;
                margin:500px auto 200px; 
                .team_img{
                    width:900px;
                    height:440px;
                    overflow: hidden;
                    img{
                        transform: scale(1.3);
                        width:100%;
                    }
                }
               
                .team_desc{
                    margin-left:40%;
                    margin-top:70px;
                    width:650px;
                    font-size:18px;
                    line-height: 24px;
                }
            }
        }

    }
    .contact{
        &.c1{
            padding:190px 0px 0px;
            .main_copy{
                padding:0px $container_space;
                font-size:3.6vw;
                line-height: 3.5vw;  
               
                
            }
            .line_t{
                margin-top:-2.7vw;
            }
            .company_container{
                margin-top:80px;
                display:flex;
                
                position: relative;
                .deco_img{
                    margin-top:120px;
                    width:42%;
                    font-size:0px;
                    overflow: hidden;
                    margin-right:8%;
                    clip-path: inset(0);
                    height:1100px;
                    background-color: #f2f2f2;
                    img{
                        position: fixed;
                        left: 0;
                        top: 0px;
                        width:42%;
                        height:100%;
                      
                        object-fit: cover;
                    }
                }
                .company_section{
                  
                    padding-left:0%;
                    width:40%;
                    box-sizing: border-box;
                    .co_desc{
                        width:90%;
                        font-size:18px;
                        line-height: 24px;
                    }
                    .co_contact_items{
                       
                        margin-top:65px;
                        display:flex;
                        flex-wrap: wrap;
                        gap:10px;
                        justify-content: space-between;
                        .coc_item{
                            display:inline-block;
                            font-size:24px;
                            line-height: 29px;
                            &.full_item{
                                margin-top:30px;
                                width:100%;
                            }
                            .coc_title{
    
                            }
                        }
                    }
                    .recruit_section{
                        margin-top:115px;
                        font-size:24px;
                        line-height: 29px;
                        &>.re_title{
                            border-bottom:1px solid $black;
                            padding-bottom:12px;
                        }
                        .re_items{
                           .re_item{
                                height:68px;
                                display:flex;
                                align-items: center;
                              
                                border-bottom:1px solid $black;
                                justify-content: space-between;
                           }
                        }
                    }
                    
                }
            }
        }

    }
    .projects{
        display:flex;
        border-top:1px solid $black;
        margin-top:60px;
        display:flex;
       
        .pr_items{
            border-right:1px solid $black;
            width:600px;
            padding-bottom:100px;
            .pr_item{
                display:flex;
                border-bottom:1px solid $black;
                
            }
            .year_item{
                width:200px;
                font-size:22px;
                padding:16px $container_space;
                line-height: 26px;
                border-right:1px solid $black;
            }
            .title_item{
                flex:1;
                display:flex;
                padding:0px 30px;
                height:90px;
                align-items: center;
                border-bottom:1px solid $black;
                box-sizing: border-box;
                width:400px;
                font-size:17px;
                gap:20px;
                cursor: pointer;
                &:last-child{
                    border-bottom:none;
                }
                &.active{
                    .title_deco{
                        .td_item:nth-child(2){
                            transform: rotate(90deg);
                        }
                        .td_item:nth-child(3){
                            transform: rotate(45deg);
                        }
                        .td_item:nth-child(4){
                            transform: rotate(135deg);
                        }
                    }
                }
                .title_deco{
                    position: relative;
                    width:23px;
                    .td_item{
                        position: absolute;
                        width:23px;
                        height:1px;
                        top:0px;
                        background-color: $black;
                        transition-duration: 0.2s;

                    }
                }
            }
        }
        .project_summary{
            flex:1;
            padding:16px 24px 70px;
            font-size:22px;
            line-height: 26px;
            overflow: hidden;
            position: relative;

            .back_bt{
                display:none;
            }
            &>.more_bt{
                position: fixed;
                z-index: 10;
                left:60%;
                top:30%;
                width:125px;
                height:125px;
                border-radius: 50%;
                display:flex;
                justify-content: center;
                align-items: center;
                font-size:18px;
                line-height: 18px;
                color:#fff;
                background: rgba(0, 0, 0, 0.7);
border: 1px solid #181818;
backdrop-filter: blur(20px);
margin-left:-62px;
margin-top:-62px;
                pointer-events: none;
                transform: scale(0);

            }
            button.more_bt{
                display:none;
            }
            .pr_info{
                display:flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top:34px;
                .pr_title{
                    a{
                        display:none;
                    }
                }
                .pr_subinfo_items{
                    display:flex;
                    gap:30px;
                }

            }
            .pr_img_section{
                margin-top:10px;
                display:flex;
                justify-content: flex-start;
                
                gap:15px;
                cursor:none;
                a{
                    cursor:none;
                }
                .pri_item{
                    background-color: $black;
                    line-height: 0px;
                    height:720px;
                    img{
                        width:100%;
                        height:720px;
                        object-fit: cover;
                    }
                    &.pri_1{
                        width:450px;
                      
                    }
                    &.pri_2{
                        width:1400px;
                       
                    }
                }
            }
            .pr_desc{
                margin-top:20px;
                font-size:18px;
                line-height:25px;
            }
        }
    }
    .project_detail{
        &.pd1{
          
            .top_img{
                background-color: $black;
                font-size:0px;
                img{
                    width:100%;
                    height:700px;
                    object-fit: cover;
                }
            }
            .pr_info{
                display:flex;
                border-top:1px solid $black;
                border-bottom:1px solid $black;
                font-size:22px;
                line-height: 26px;
                height:320px;

                .pr_title{

                    width:50%;
                    border-right:1px solid $black;
                    padding:20px $container_space;
                    box-sizing: border-box;
                   
                }
                .pr_info_right_section{
                    flex:1;
                    padding:20px $container_space;
                    .pr_info_items{
                        display:flex;
                        justify-content: flex-start;
                        gap: 8px 4%;
                        flex-wrap: wrap;
                      
                    }
                    .pr_desc{
                        font-size:18px;
                        line-height: 22px;;
                        margin-top:30px;
                        width:95%;
                    }
                    
                }
            }
            
        }
        &.pd2{
            padding:$container_space;
         
            .thumbnail_img{
                display:flex;
                gap:15px;
                .thumb1{
                    width:500px;
                    height:570px;
                    object-fit: cover;
                }
                .thumb2{
                    width:740;
                    height:570px;
                    object-fit: cover;
                }
            }
            .contents_imgs{
                margin-top:130px;
                display:flex;
                flex-direction: column;
                gap:50px;
               
                align-items: center;
                img{
                    max-width: 1200px;
                }
            }
        }
        &.pd3{
           
            .project_recent_section{
                .pr_section_title{
                    font-size:40px;
                    line-height:48px;
                }
            }
        }
    }
    .news_list{
        padding:120px 0px 180px;
        .section_title{
            font-size:38px;
            line-height: 48px;
            padding:0px $container_space;
        }
        .news_items{
            margin-top:15px;
            border-top:1px solid $black;
            .news_item{
                height:80px;
                display:flex;
                padding:0px $container_space;
                align-items: center;
                font-size:20px;
                gap:20px;
                border-bottom:1px solid $black;
                cursor: pointer;
                .news_cate{
                    min-width:160px;
                    width:10%;
                }
                .news_title{
                    flex:1;
                }
                .news_date{
                    width:150px;
                    text-align: right;;
                }

            }
        }
        .more_bt_section{
            padding:30px $container_space;

        }
    }
    .news_detail{
        display:flex;
        border-top:1px solid $black;
        margin-top:60px;
        display:flex;
        
        .news_left_section{
            border-right:1px solid $black;
            flex:1;
            padding:28px $container_space;

        }

        .news_right_section{
            flex:1;
            border-left:1px solid $black;
        }
        .news_contents_section{
            width:960px;
            padding-bottom:150px;
            .news_header{
                display:flex;
                font-size:20px;
                height:80px;
                padding:0px $container_space;
                align-items: center;
                justify-content: space-between;
                .news_title{
                    flex:1;
                }
                .news_info{
                    display:flex;
                    gap:40px;
                }
                
                
            }
            .news_img{
               
                img{
                    width:100%;
                    min-height: 100px;
                    border-top:1px solid $black;
                    border-bottom:1px solid $black;
                }
            }
            .news_contents{
                padding:$container_space;
                font-size:18px;
                line-height: 26px;
                p{
                    margin-bottom:10px;
                }
                .bt_section{
                    margin-top:100px;
                }
            }

        }

    }
    .project_recent_section{
        padding-left:$container_space;
        padding:200px $container_space;
        .pr_section_title{
            font-size:50px;
            line-height:60px;
        }
        .pr_recent_items{
            margin-top:34px;
            .pr_item{
                width:670px;
                cursor: pointer;
                .pr_img{
                   img{
                    width:100%;
                    height:420px;
                    object-fit: cover;
                   }
                }
                .pr_info{
                    display:flex;
                    justify-content: space-between;
                    .pr_title{
                        .prt_en{
                            font-size:20px;
                            line-height: 24px;
                        }
                        .prt_kr{
                            font-size:17px;
                            line-height: 24px;

                        }
                    }
                    .pr_subinfo{
                        display:flex;
                        gap:25px;
                        font-size:20px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
.footer{
    height:450px;
    border-top:1px solid $black;
    display:flex;
    font-size:22px;
    line-height: 22px;
    .left_section{
        border-right:1px solid $black;
        width:50%;
        padding:20px $container_space;
        position: relative;
        box-sizing: border-box;
        .company_desc{
            position: absolute;
            left:$container_space;
            bottom:20px;
            width:500px;
            line-height: 24px;
        }
    }
    .right_section{
        flex:1;
        padding:20px $container_space;
        position: relative;
        .company_sns{
            position: absolute;
            right:$container_space;
            top:20px;
        }
        .company_copyright{
            position: absolute;
            right:$container_space;
            bottom:20px;
            font-size:16px;
        }
       
    }
}
.default_bt{
    display:flex;
    align-items: center;
    cursor: pointer;
    &.more_bt,&.back_bt{
        font-size:20px;
        line-height: 24px;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 1600px) and (min-width: 900px) {
    .contents_wrap{

        .contact{
            &.c1{
                .company_container{
                    .deco_img{
                        margin-top:140px;
                    }
                }

            }
        }
    }
  }