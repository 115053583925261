$black:#181818;
$container_space:15px; //페이지별 좌우 기본 여백
@media only screen and (max-width: 900px) {
    .mobile_view{
        display:initial;

    }
    .move_back{
        .logo{
            height:12px;

        }
    }
    .header{
        left:0px;
        right:0px;
        padding:0px $container_space;
        top:12px;
        z-index: 999;
        align-items: flex-start;
        .main_menu{
            pointer-events: none;
            display:none;

        }
        .company_info{
            opacity: 0;
            pointer-events: none;
            transition-duration: 0.3s;
            z-index:999;
                position: fixed;
                bottom:20px;
                left:$container_space;
                color:#fff;
                font-size:15px;
                line-height: 17px;
        }
        &:after{
            z-index: -1;
            opacity: 0;
            content:'';
            position: fixed;
            top:0px;
            bottom:0px;
            left:0px;
            right:0px;
            background-color: $black;
            transition-duration: 0.3s;
            pointer-events: none;

            
        }
        &.active{
            mix-blend-mode: initial;
            bottom:0px;
            display:block;
            
            &:after{
                opacity: 1;
            }
          
            .main_menu{
                display:flex;
                pointer-events: initial;
                position: absolute;
                left:0px;
                top:25%;
                flex-direction: column;
                align-items: flex-start;
                right:0px;
                gap:0px;
              
                li{
                    animation-delay: 0.2s;
                    animation: show_in 0.4s forwards;
                    border-bottom:1px solid #fff;
                    width:100%;
                    padding:15px $container_space;
                }
                @keyframes show_in {
                    0%{
                            width:0%;
                            opacity: 0;
                            //padding:0px $container_space;
                      
                    }
                    100%{
                        width:100%;
                            opacity: 1;
                            //padding:15px $container_space;

                        
                    }
                    
                } 
                
            }
            .company_info{
                opacity: 1;
                
            }
        }
        .logo{
            img{
                height:12px;
            }
        }
        .mobile_menu{
            position: fixed;
            display:initial;
            color:#fafafa;
            font-size:15px;
            right:$container_space;
            top:12px;
        }
       
    }
    .line_t{
        overflow: hidden;   
        line-height: 7.8vw;
        margin-top:-1.5vw;
    }
    .contents_wrap{
        .main{
            &.m1{
                padding-top:235px;
                .main_copy{
                    top:100px;
                    height:initial;
                    left:$container_space;
                    font-size:7vw;
                    line-height: 6vw;
                    .copy_space{
                        width:10vw;
                        &.copy_space3{
                            width:21.2vw;

                        }
                    }
                    &.mc1{
                        margin-top:0.3vw;
                    }
                    &.mc2{
                        .line_down{
                            margin-top:2px;
                        }
                    }
                }
                .sub_info{
                    margin-top:0px;
                    padding:0px $container_space;
                    .si_item{
                        font-size:13px;
                        line-height: 16px;
                    }
                    .si_right_section{

                        gap:10px;
                    }
                }
                .main_img{
                    margin-top:10px;
                    padding:20px $container_space;
                    height:700px;
                    overflow: hidden;
                    .arrow_down{
                        width:34px;
                        margin-left:-17px;
                        bottom:20px;
                       
                    }
                    .back_img{
                        height:700px;
                        &.bi2{
                            width:140%;
                        }
                    }
                    .sub_copy{
                        font-size:14px;
                        line-height: 17px;
                        width:100%;
                        &.sc2{
                            top:400px;
                            left:0px;
                            width:100%;
                            box-sizing: border-box;
                            padding:0px $container_space;
                        }

                    }
                }
            }
            &.m2{
                margin-top:100px;
                padding-top:100px;
                .business_section{
                    margin-top:0px;
                    height:initial;
                    .bs_title_items{
                       display:none;
                    }
                    .business_desc{
                        font-size:14px;
                        line-height: 15px;
                        left:50%;
                        top:-50px;
                    }
                    .bs_img_items{
                        height:400px;
                        .bs_img_item{
                            display:none;

                            &.bi3{
                                display:initial;
                                width:100%;
                                img{
                                    width:100%;
                                    height:400px;
                                }
                            }
                        }
                    }
                }
                .team_section{
                    width:100%;
                    padding:0px $container_space;
                    box-sizing: border-box;
                    margin:50px 0px 10px;
                    .team_img{
                        width:100%;
                        height:170px;
                        img{
                            transform: scale(1);
                        }
                    }
                  
                    .team_desc{
                        margin-left:0px;
                        margin-top:10px;
                        font-size:14px;
                        line-height: 17px;
                        width:100%;
                    }
                }
            }
        }
        .news_list{
            padding:63px 0px 100px;
            .section_title{
                font-size:18px;
                padding:0px $container_space;
                line-height: 18px;
            }
            .news_items{
                margin-top:10px;
                .news_item{
                    position: relative;
                    padding:12px $container_space;
                    font-size:14px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap:5px;
                    height:initial;
                    .news_title{
                        width:100%;
                        height:20px;
                        flex: initial;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    .news_date{
                        position: absolute;
                        right:$container_space;
                        top:11px;
                    }
                }
            }
            .more_bt_section{
                padding:$container_space;
            }
        }
        .news_detail{
            flex-direction: column;
            margin-top:60px;
            border-top:none;
            .news_left_section{
                border-right:none;
                padding:10px $container_space;
            }
            .news_contents_section{
                width:100%;
                padding-bottom:70px;
                .news_header{
                    font-size:14px;
                    position: relative;
                    border-top:1px solid $black;
                    padding:13px $container_space;
                    height:initial;
                    line-height: 17px;
                    .news_info{
                        
                        position: absolute;
                        top:-30px;
                        right:$container_space;
                        gap:20px;
                    }
                    
                }
                .news_img{
                    font-size:0px;
                }
                .news_contents{
                    font-size:14px;
                    line-height: 18px;
                    padding:$container_space;
                    font-weight:400;
                    font-family:'Noto Sans Korean',sans-serif;
                    .bt_section{
                        margin-top:30px;
                    }
                }
            }
            .news_right_section{
                display:none;
            }
        }
        .projects{
            flex-direction: column;
            height:100%;
            margin-top:40px;
            position: relative;
            .pr_items{
                .pr_item{
                    flex-direction: column;
                    .year_item{
                        font-size:15px;
                        width:100%;
                        border-right: none;
                        padding:5px $container_space;
                        border-bottom:1px solid $black;
                    }
                }
            }
            .year_items{
                
                width:100%;
                box-sizing: border-box;
                border-right:none;
                padding:4px $container_space;
                font-size:18px;
                border-bottom:1px solid $black;
                overflow: scroll;
-ms-overflow-style: none;
scrollbar-width: none;
                .year_scorll{
                    width:150%;
                    .year_item{
                        display:inline-block;
                        margin-right:15px;
                    }
                }
                &::-webkit-scrollbar {
                    display: none;
                    }
            }
            .title_items{
                border-right:none;
                .title_item{
                    height:75px;
                    font-size:15px;
                    width:100%;
                    padding:0px $container_space;
                    gap:18px;
                    .title_deco{
                        width:16px;
                        .td_item{
                            width:16px;
                        }
                    }
                }
            }
            .project_summary{
                line-height: 18px;

                animation: show-in 0.4s ease-in;
                
                @keyframes show-in{
                    0%{
                        opacity: 0;
                        left:100%;
                        right:-300%;
                        width:50%;
                    }
                    100%{

                        opacity: 1;
                        left:0%;
                        right:0px;
                        width:100%;
                    }

                }
                
                &>.more_bt{
                    display:none;
                }
                button.more_bt{
                    display:initial;
                }
                .pr_desc{
                    display:none;
                }
                
                .bt_section{
                    display:block;
                    margin-top:10px;

                }
                position: absolute;
                left:0px;
                top:41px;
                width: 100%;
                bottom:0px;
                box-sizing: border-box;
                background-color: #fff;
                padding:11px $container_space;
                font-size:15px;
                .back_bt{
                    display:initial;
                }
                .pr_info{
                    margin-top:50px;
                    .pr_title{
                        font-size:18px;
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        width:100%;
                        &>div{
                            flex:1;
                        }
                        a{
                            display:initial;
                        }
                        .more_bt{
                            font-size:15px;
                        }
                    }
                       .pr_subinfo_items{
                    position: absolute;
                    top:10px;
                    right:$container_space;
                    gap:20px;
                }
                }
             
                .section_title{
                    display:none;
                }
                .pr_img_section{
                    flex-direction: column;
                    width:100%;
                    gap:10px;
                    .swiper-wrapper{
                        flex-direction: column;
                        gap:10px;
                    }

                    .pri_item{
                       line-height: 0px;
                       height:initial;
                        img{
                            height:240px;
                        }
                        &.pri_1{
                            width:100%;
                        }
                        &.pri_2{
                            width:100%;
                        }
                    }
                }
                
            }
        }
        .project_detail{
            &.pd1{
                .top_img{
                    img{
                        height:240px;
                    }
                }
                .pr_info{
                    flex-direction: column;
                    height:initial;
                    padding-bottom:20px;
                    .pr_title{
                        width:100%;
                        border-right:none;
                        padding:15px $container_space;
                        font-size:20px;
                    }
                    .pr_info_right_section{
                        padding:0px $container_space;
                        font-size:14px;
                        line-height: 17px;
                        
                        .pr_desc{
                            font-size:14px;
                            line-height: 18px;
                            margin-top:20px;
                            width:100%;
                        }
                        .pr_info_items{
                            
                        }
                    }
                }
            }
            &.pd2{
                padding:$container_space;
                .thumbnail_img{
                    flex-direction: column;
                    gap:10px;
                
                    img{
                        &.thumb1, &.thumb2{
                            width:100%;
                            height:initial;
                            object-fit: initial;;
                        }
                    }

                }
                .contents_imgs{
                    margin-top:50px;
                    gap:20px;
                    img{
                        max-width: 100%;
                    }
                }
            }
            &.pd3{
                .project_recent_section{
                    
                    .pr_section_title{
                        font-size:15px;
                        line-height: 18px;;
                    }
                 }
            }
        }
         .contact{
            &.c1{
                padding-top:100px;
                .main_copy{
                    width:100%;
                    box-sizing: border-box;
                    padding:0px $container_space;
                    font-size:5vw;
                    line-height: 19px;
                    .copy_space{
                        width:8vw;
                    }
                }
                .company_container{
                    margin-top:24px;
                    flex-direction:column-reverse;
                    .deco_img{
                        width:100%;
                        margin-top:0px;
                        height:100px;
                        margin-right:0px;
                        //clip-path:inita
                        img{
                            top:100px;
                            width:100%;
                            height:550px
                            
                        }
                    }
                    .company_section{
                        width:100%;
                        .co_desc{
                            font-size:14px;
                            padding:0px $container_space;
                            line-height:17px;
                        }
                        .co_contact_items{
                            margin-top:50px;
                            padding:0px $container_space;
                            .coc_item{
                                font-size:14px;
                                line-height: 17px;
                                &.full_item{
                                    margin-top:0px;
                                }
                            }
                           

                        }
                        .recruit_section{
                            font-size:14px;
                            line-height: 17px;
                            margin-top:40px;
                            &>.re_title{
                                padding:8px $container_space;
                            }
                            .re_items{
                                .re_item{
                                    height:50px;
                                    padding:0px $container_space;
                                }
                            }
                        }
                        
                    }
                }

            }
         }   
         .project_recent_section{
            padding:60px $container_space;
            .pr_section_title{
                font-size:15px;
                line-height: 18px;;
            }
            .pr_recent_items{
                margin-top:10px;
                .pr_item{
                    width:90%;
                    .pr_img{
                        img{
                            height:200px;
                        }
                    }
                    .pr_info{
                        .pr_title{
                            .prt_en{
                                font-size:15px;
                                line-height: 18px;
                                width:180px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .prt_kr{
                                font-size:14px;
                                line-height: 17px;
                            }
                        }
                        .pr_subinfo{
                            font-size:15px;
                                line-height: 18px;
                                gap:10px;
                        }
                    }
                }
            }
         }
    }
    
    .footer{
        flex-direction: column;
        font-size:15px;
        line-height: 15px;
        position: relative;
        height:370px;
        .left_section{
            width:100%;
            padding:17px $container_space;
            border-right:none;
            .company_desc{
                position: relative;
                left:inherit;
                bottom:initial;
                margin-top:30px;
                width:100%;
                line-height: 16px;;
            }
        }
        .right_section{
            position: initial;
            padding:15px $container_space;
            border-top:1px solid $black;
            .company_sns{
                top:15px;
                right:$container_space;
            }
            .company_copyright{
                right:$container_space;
                font-size:14px;
                bottom:15px;
            }
        }
    }

    .default_bt{
       &.more_bt,&.back_bt{
            font-size:14px;
            font-family:"Neue Montreal",'Noto Sans Korean', sans-serif;
        }
    }

}